import './App.css';
import Header from './Header';
import Footer from './Footer';

function App() {
  return (
    <>
      <div>
        <Header />
        <Footer />
      </div>
    </>
  );
}

export default App;
